import fullpage from 'fullpage.js';
import { gsap }   from 'gsap';

gsap.config({
	nullTargetWarn : false,
});
(() => {
	new fullpage('#fullpage', {
		navigation         : true,
		scrollbar          : false,
		autoScrolling      : true,
		navigationPosition : 'right',
		menu               : '#menu',
		anchors            : ['home', 'our-work', 'our-lab', 'about-us', 'contact-us'],
		easing             : 'easeInOutCubic',
		scrollingSpeed     : 900,

		afterLoad(origin, destination, direction) {

		},
		onLeave : (origin, destination, direction) => {
			const section = destination.item;
			const title   = section.querySelector('.title');
			const text    = section.querySelector('.text');
			const buttons = section.querySelector('.b-btn--circle');
			const tl      = gsap.timeline({ delay : 0.5 });

			tl.fromTo(
				title,
				0.5,
				{
					y       : '50',
					opacity : 0,
				},
				{
					y       : 0,
					opacity : 1,
				},
			).fromTo(
				text,
				0.6,
				{
					y       : '50',
					opacity : 0,
				},
				{
					y       : 0,
					opacity : 1,
				},
			).fromTo(
				buttons,
				0.7,
				{
					x       : '-50',
					opacity : 0,
				},
				{
					x       : 0,
					opacity : 1,
				},
			);
			if (destination.index === 0) {
				const vid = document.getElementById("phanter");
				vid.autoplay = true;
				vid.load();
			}
			if (destination.index === 1) {
				tl.fromTo(
					'.our-works__image',
					0.5,
					{
						x       : '-50',
						opacity : 0,
					},
					{
						x       : 0,
						opacity : 1,
					},
				);
			}
			if (destination.index === 3) {
				tl.fromTo(
					'.about__img',
					0.5,
					{
						bottom  : 0,
						opacity : 0,
					},
					{
						bottom  : 0,
						opacity : 1,
					},
				);
			}
		},
	});
})();
